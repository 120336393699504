import { X, Menu } from "lucide-react";
import { useState, useEffect, useRef } from "react";
import ContactFormModal from "./ContactFormModal";
import AboutModal from "./AboutModal";

function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      <header className="fixed top-0 left-0 right-0 p-4 flex justify-between items-center text-white bg-gray-900 z-50 shadow-lg">
        <div className="text-2xl font-bold">Squad Buddy</div>
        <nav className="hidden md:flex space-x-4">
          <button onClick={() => setIsContactModalOpen(true)}>Contact</button>
          <button onClick={() => setIsAboutModalOpen(true)}>About</button>
        </nav>
        <div className="md:hidden">
          <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
            {isMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </div>
      </header>

      {isMenuOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
          <div
            ref={menuRef}
            className="bg-gray-800 rounded-lg shadow-lg p-4 flex flex-col space-y-4"
          >
            <button
              className="text-white text-lg"
              onClick={() => {
                setIsContactModalOpen(true);
                setIsMenuOpen(false);
              }}
            >
              Contact
            </button>
            <button
              className="text-white text-lg"
              onClick={() => {
                setIsAboutModalOpen(true);
                setIsMenuOpen(false);
              }}
            >
              About
            </button>
          </div>
        </div>
      )}

      <div className="h-16"></div>

      <ContactFormModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />

      <AboutModal
        isOpen={isAboutModalOpen}
        onClose={() => setIsAboutModalOpen(false)}
      />
    </>
  );
}

export default NavBar;
