import React, { useState, useEffect } from "react";
import { X, Send, AlertCircle } from "lucide-react";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { Alert, AlertDescription } from "../components/ui/alert";

const ContactFormModal = ({ isOpen, onClose }) => {
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [honeypot, setHoneypot] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [lastSubmitTime, setLastSubmitTime] = useState(0);
  const [timeUntilNextPost, setTimeUntilNextPost] = useState(0);

  useEffect(() => {
    const storedCount = localStorage.getItem("submitCount");
    const storedTime = localStorage.getItem("lastSubmitTime");
    if (storedCount) setSubmitCount(parseInt(storedCount));
    if (storedTime) setLastSubmitTime(parseInt(storedTime));
  }, []);

  useEffect(() => {
    const checkPostAvailability = () => {
      const now = Date.now();
      const timeSinceLastSubmit = now - lastSubmitTime;
      const cooldownPeriod = 60000; // 1 minute cooldown

      if (timeSinceLastSubmit < cooldownPeriod) {
        setTimeUntilNextPost(
          Math.ceil((cooldownPeriod - timeSinceLastSubmit) / 1000)
        );
      } else {
        setTimeUntilNextPost(0);
      }
    };

    checkPostAvailability();
    const interval = setInterval(checkPostAvailability, 1000);

    return () => clearInterval(interval);
  }, [lastSubmitTime]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check honeypot
    if (honeypot) {
      console.log("Potential spam detected");
      return;
    }

    // Rate limiting
    if (timeUntilNextPost > 0 || submitCount >= 5) {
      alert("Too many requests. Please try again later.");
      return;
    }

    setIsSubmitting(true);

    try {
      const db = getFirestore();
      await addDoc(collection(db, "contactMessages"), {
        subject,
        email,
        message,
        timestamp: serverTimestamp(),
      });

      setSubject("");
      setEmail("");
      setMessage("");
      setSubmitCount((prevCount) => prevCount + 1);
      setLastSubmitTime(Date.now());
      localStorage.setItem("submitCount", submitCount + 1);
      localStorage.setItem("lastSubmitTime", Date.now());

      alert("Message sent!");
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred. Please try again later.");
    }

    setIsSubmitting(false);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-xl font-semibold text-black">Contact us</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          <div className="mb-4">
            <label
              htmlFor="subject"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Subject
            </label>
            <select
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 text-black rounded-md focus:outline-none focus:ring-2 focus:ring-lime-500"
              required
            >
              <option value="">Choose a subject</option>
              <option value="design">Design idea</option>
              <option value="problem">Problem</option>
              <option value="business">Business Question</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-500 text-black"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Message
            </label>
            <textarea
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              rows="4"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-lime-500 text-black"
              required
            ></textarea>
          </div>
          <div className="hidden">
            <input
              type="text"
              value={honeypot}
              onChange={(e) => setHoneypot(e.target.value)}
              tabIndex="-1"
              autoComplete="off"
            />
          </div>
          <div className="mt-6">
            <button
              type="submit"
              disabled={isSubmitting || timeUntilNextPost > 0}
              className="w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-lime-400 hover:bg-lime-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? "Sending..." : "Send"}
              <Send size={18} className="ml-2" />
            </button>
          </div>
          {timeUntilNextPost > 0 && (
            <Alert className="mt-2 transition-all duration-300 ease-in-out">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>
                You can submit again in {timeUntilNextPost} seconds.
              </AlertDescription>
            </Alert>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactFormModal;
