import { useState, useEffect, lazy, Suspense, memo } from "react";
import "./tailwind-output.css";
import NavBar from "./components/NavBar";
import ErrorBoundary from "./components/ErrorBoundary";
import { analytics, logEvent } from "./firebase/firebase";

const Footer = lazy(() => import("./components/Footer"));
const BuddyList = lazy(() => import("./components/BuddyList"));
const AddForm = lazy(() => import("./components/AddForm"));
const ResponsiveImage = lazy(() => import("./components/ResponsiveImage"));

const WELCOME_TEXT =
  "Welcome to SquadBuddy.gg! Here, you can find and connect with other SquadBusters players. Browse the list below to find a new Squad Buddy, or add your Player ID to let others find and add you. Happy gaming!";

const JumboBackground = memo(() => (
  <>
    <div className="absolute top-0 left-1/3 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob"></div>
    <div className="absolute top-0 right-1/3 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-2000"></div>
    <div className="absolute bottom-8 left-1/3 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-50 animate-blob animation-delay-4000"></div>
  </>
));

const App = () => {
  const [buddies, setBuddies] = useState([]);

  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view");
    }
  }, []);

  return (
    <ErrorBoundary>
      <div className="min-h-screen text-black flex flex-col overflow-x-hidden">
        <Suspense fallback={<div>Loading...</div>}>
          <NavBar />

          <main className="flex-grow flex flex-col items-center px-4 py-12 mt-8">
            <div className="relative w-full max-w-6xl">
              <JumboBackground />
              <div className="bg-white shadow-lg rounded-lg mb-4 w-full opacity-100">
                <h1 className="text-3xl md:text-4xl font-bold mb-2 text-center pt-4">
                  Find A Squad Buddy
                </h1>
                <p className="text-center text-base md:text-lg leading-relaxed max-w-2xl mx-auto mb-8 px-4">
                  {WELCOME_TEXT}
                </p>
                <div className="flex flex-col sm:flex-row items-center relative justify-between px-4 pb-4 space-y-4 sm:space-y-0">
                  <Suspense fallback={<div>Loading image...</div>}>
                    {/* Left image and AddForm container */}
                    <div className="w-full sm:w-3/4 flex flex-col sm:flex-row items-center relative">
                      {/* Bandit Sticker */}
                      {/* <div className="w-24 h-24 absolute -left-20 top-0 z-10">
                        <ResponsiveImage
                          src="./img/bandit_sticker"
                          alt="Bandit Sticker"
                          className="w-full h-full object-contain"
                          sizes="(max-width: 640px) 96px, 1px"
                          maxSize={300}
                        />
                      </div> */}

                      {/* Optimus Prime for larger screens */}
                      <div className="hidden sm:block sm:w-1/3">
                        <ResponsiveImage
                          src="./img/Poco_Lantern"
                          alt="Poco Portrait"
                          className="w-full h-auto object-contain"
                          sizes="(min-width: 640px) 25vw, 1px"
                        />
                      </div>

                      {/* AddForm */}
                      <div className="w-full sm:w-2/3 pt-8 sm:pt-0 sm:pl-0 relative">
                        <AddForm setBuddies={setBuddies} buddies={buddies} />
                      </div>
                    </div>

                    {/* Right image */}
                    <div className="hidden sm:block sm:w-1/4">
                      <ResponsiveImage
                        src="./img/ColonelRuffs_classic"
                        alt="ColonelRuffs Portrait"
                        className="w-full h-auto object-contain"
                        sizes="(min-width: 640px) 25vw, 1px"
                      />
                    </div>
                  </Suspense>
                </div>
              </div>
            </div>
            <BuddyList buddies={buddies} setBuddies={setBuddies} />
          </main>

          <Footer />
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default memo(App);
