import React from 'react';
import { X } from "lucide-react";

const AboutModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white text-black p-6 rounded-lg max-w-md w-full m-4 mt-12 max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">About Squad Buddy</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <div>
          <h2 className="text-2xl font-bold">Our Mission</h2>
          <p className="mb-8">
            Our mission is simple: to create a community where SquadBusters
            enthusiasts can easily find and connect with each other. We
            understand the importance of having a reliable team to play
            with, and SquadBuddy.gg is here to bridge that gap.
          </p>

          <h2 className="text-2xl font-bold mt-4">What We Offer</h2>
          <p className="mb-8">
            SquadBuddy.gg allows users to add their Player ID, making it
            easier than ever to find and add friends directly within the
            game. Whether you're looking for new teammates or trying to
            reconnect with old friends, our platform is designed to enhance
            your SquadBusters experience.
          </p>

          <h2 className="text-2xl font-bold mt-8">Who We Are</h2>
          <p className="mb-8">
            SquadBuddy.gg was created as a school project by Nicklas
            Jarinder, a passionate gamer and aspiring web developer. While
            we are not officially affiliated with Supercell or the creators
            of SquadBusters, we share a common love for the game and a
            desire to build a strong, supportive community around it.
          </p>

          <h2 className="text-2xl font-bold mb-1">Join Us</h2>
          <p className="mb-8">
            We invite you to join our growing community, connect with other
            players, and take your SquadBusters gameplay to the next level.
            Thank you for visiting SquadBuddy.gg, and we look forward to
            seeing you in the game!
          </p>

          <p className="mb-6">
            As fans of SquadBusters, we want to acknowledge that all the images used on this website are created by and sourced from Supercell. We greatly appreciate their fantastic work in creating the game and its visuals, which have inspired our community-building efforts.
          </p>

          <p className="mb-6">Happy gaming!</p>

          <p className="mb-6">
            <strong>Nicklas Jarinder</strong>
            <br />
            Founder of SquadBuddy.gg
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;